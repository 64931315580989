import Typed from 'typed.js';

/**
 * Class TypedInitializer
 */
export class TypedInitializer {
  constructor() {
    this.defaultOptions = {
      typeSpeed: 30,
      backSpeed: 30,
      backDelay: 2000,
      loop: true
    }
  }

  /**
   * Initializes Typed on every `data-typed` attribute.
   *
   * These are the possible data attributes:
   *
   * - **data-options** (optional): Options of countup.js @see https://inorganik.github.io/countUp.js/
   */
  initialize() {
    document.querySelectorAll('[data-typed]').forEach((el) => {
      new Typed(el, {
        ...this.defaultOptions,
        ...(el.dataset.options) ? JSON.parse(el.dataset.options) : {}
      });
    });
  }
}

export default new TypedInitializer();
